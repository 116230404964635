import React, { useState, useEffect, useCallback } from "react";
import "./Video.css";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { API_URL, getDaysAgo, formatNumber } from "../globals";

export default function Video({ id, thumbnailOnly = false, title: propTitle }) {
  const [videoData, setVideoData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [views, setViews] = useState(0);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchVideoData = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/videos/${id}`);
      const data = await response.json();
      setVideoData(data);
      const userResponse = await fetch(`${API_URL}/user/${data.user_id}`);
      const viewResponse = await fetch(`${API_URL}/videos/${id}/views`);
      const viewData = await viewResponse.json();
      setViews(viewData.views);
      const userData = await userResponse.json();
      setUserData(userData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching video data:", error);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchVideoData();
  }, [fetchVideoData]);

  useEffect(() => {
    if (propTitle && videoData) {
      setVideoData(prev => ({ ...prev, title: propTitle }));
    }
  }, [propTitle]);

  const handleVideoClick = () => {
    // navigate(`${API_URL}/videos/details/${id}`);
    navigate(`/videos/details/${id}`);
  };
  if (!videoData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="video-container" onClick={handleVideoClick}>
      {thumbnailOnly ? (
        <div className="video-thumbnail-wrapper">
          <img 
            src={videoData?.thumbnail_filepath} 
            alt={videoData?.title}
            className="video-thumbnail"
          />
        </div>
      ) : (
        <>
          <div className="video-thumbnail-wrapper">
            <img
              className="video-thumbnail"
              src={videoData?.thumbnail_filepath}
              alt={videoData?.title}
            />
            <div className="play-icon">▶</div>
          </div>

          <div className="video-info">
            <div className="video-info-avatar">
              <Avatar
                src={userData && userData.photo_url ? userData.photo_url : ""}
                alt="Profile"
                className="profile-icon"
              />
              <h4 className="video-title">{videoData?.title}</h4>
            </div>

            <div className="video-details">
              <p className="video-username">{userData?.display_name}</p>

              <div className="video-share-info">
                <p className="video-shares">
                  Market Cap: $
                  {formatNumber(
                    videoData?.shares_created * videoData?.share_price
                  )}
                </p>
                <p className="video-cost">
                  {" "}
                  ${videoData?.share_price} cost per share
                </p>

                <p className="video-views"> {views} views</p>
                <p className="video-updated-timestamp">
                  {getDaysAgo(videoData?.created_at)}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
