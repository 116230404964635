export const API_URL = process.env.REACT_APP_API_URL;

export function getDaysAgo(dateString) {
  const commentDate = new Date(dateString);
  const now = new Date();

  // Calculate the difference in milliseconds
  const difference = now - commentDate;

  // Convert difference from milliseconds to days
  const daysAgo = Math.floor(difference / (1000 * 60 * 60 * 24));

  // If less than one day ago, calculate hours ago
  if (daysAgo === 0) {
    const hoursAgo = Math.floor(difference / (1000 * 60 * 60));
    return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  }

  // If less than 30 days ago, return days ago
  if (daysAgo < 30) {
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  }

  // Convert days to months, if over 30 days
  const monthsAgo = Math.floor(daysAgo / 30);
  return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
}

export function formatNumber(value) {
  if (typeof value !== "number") {
    throw new Error("Input must be a number");
  }

  if ((value >= 1000) & (value < 1000000)) {
    const formattedValue = (value / 1000).toFixed(1);
    return `${formattedValue}K`;
  }

  if (value >= 1000000) {
    const formattedValue = (value / 1000000).toFixed(1);
    return `${formattedValue}M`;
  }

  return value.toString();
}

export function formatNumberWithCommas(number) {
  if (typeof number !== "number") {
    throw new Error("Input must be a number");
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatPercentageWithColor(number) {
  if (typeof number !== "number") {
    throw new Error("Input must be a number");
  }

  const formattedPercentage = `${number.toFixed(2)} %`;

  if (number > 0) {
    return <span style={{ color: "green" }}>{formattedPercentage}</span>;
  } else if (number < 0) {
    return <span style={{ color: "red" }}>{formattedPercentage}</span>;
  } else {
    return formattedPercentage;
  }
}