import React, { useState } from "react";
import "./CommentForm.css";

import { auth } from "../../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import Avatar from "@mui/material/Avatar";

import { createComment } from "./comment.api";

export default function CommentForm({ videoId, onCommentAdd }) {
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!comment.trim() || !user || !videoId) {
      console.error('Missing required data:', { comment, user, videoId });
      return;
    }

    setIsSubmitting(true);

    try {
      const newComment = await createComment(comment, videoId, user);
      console.log('New comment created:', newComment);
      onCommentAdd(newComment);
      setComment("");
      setIsFocused(false);
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setComment("");
    setIsFocused(false);
  };

  return (
    <div className="comment-section">
      {user && (
        <Avatar src={user.photoURL} alt={user.displayName || user.email} />
      )}

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onFocus={() => setIsFocused(true)}
          disabled={isSubmitting}
        />

        {isFocused && (
          <div className="comment-form-buttons">
            <button type="button" onClick={handleCancel} disabled={isSubmitting}>
              Cancel
            </button>
            <button type="submit" disabled={isSubmitting || !comment.trim()}>
              {isSubmitting ? 'Posting...' : 'Comment'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
