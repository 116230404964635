import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./VideoDetail.css";
import Avatar from "@mui/material/Avatar";
import Video from "./Video";
import CommentForm from "./Comments/CommentForm";
import Comment from "./Comments/Comment";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import TradeModal from "./TradeModal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TradeButton from "./TradeButton";
import { getDaysAgo, API_URL } from "../globals";
import { formatNumber, formatNumberWithCommas } from "../globals";
import Searchbar from "./Search/Header";

function VideoDetails() {
  const { id } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);
  const [tradeModalAnchor, setTradeModalAnchor] = useState(null); // State to manage popover anchor
  const [tradeType, setTradeType] = useState(null); // Track trade type: 'buy' or 'sell'
  const [views, setViews] = useState(0);
  const [likes, setLikes] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [dislikes, setDislikes] = useState(0); // New state for dislikes
  const [user] = useAuthState(auth);
  const videoRef = useRef(null);

  async function fetchVideoData() {
    try {
      const response = await fetch(`${API_URL}/videos/${id}`);
      const data = await response.json();
      setVideoData(data);
      const userResponse = await fetch(`${API_URL}/user/${data.user_id}`);
      const userData = await userResponse.json();
      setUserData(userData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching video data:", error);
      setLoading(false);
    }
  }

  async function fetchCreatorUserSubscriptionData(creator_user_id) {
    try {
      const response = await fetch(`${API_URL}/creator_subscriptions/${creator_user_id}`);
      const data = await response.json();
      setSubscribers(data.subscriberCount);
    } catch (error) {
      console.error("Error fetching creator subscription data:", error);
    }
  }

  useEffect(() => {
    if (videoData && videoData.user_id) {
      fetchCreatorUserSubscriptionData(videoData.user_id);
    }
  }, [videoData]);

  const addComment = (newComment) => {
    setComments((prevComments) => [...prevComments, newComment]);
  };

  useEffect(() => {
    async function fetchComments() {
      try {
        const response = await fetch(`${API_URL}/comments/${id}`);
        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    }

  fetchComments();

  fetchVideoData();
  }, [id]);

  const handleTradeModalClose = () => {
    setTradeModalAnchor(null);
    setIsTradeModalOpen(false);
  };

  const fetchVideoLikesAndDislikesAndViews = async () => {
    try {
      const responseLikes = await fetch(`${API_URL}/videos/${id}/likes`);
      const responseDislikes = await fetch(`${API_URL}/videos/${id}/dislikes`);
      const responseViews = await fetch(`${API_URL}/videos/${id}/views`);

      const dataLikes = await responseLikes.json();
      const dataDislikes = await responseDislikes.json();
      const dataViews = await responseViews.json();
      setLikes(dataLikes.likes);
      setDislikes(dataDislikes.dislikes);
      setViews(dataViews.views);
    } catch (err) {
      console.error("Error fetching video likes, dislikes, views", err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchVideoLikesAndDislikesAndViews();
    }, 1000); // Poll every 5 seconds

    return () => clearInterval(interval);
  }, [id]);

  useEffect(() => {
    fetchVideoData();
  }, [id]);

  useEffect(() => {
    setViews(views);
  }, [views]);

  useEffect(() => {
    setLikes(likes);
  }, [likes]);

  useEffect(() => {
    setDislikes(dislikes);
  }, [dislikes]);

  const handleVideoLike = async () => {
    try {
      const response = await fetch(`${API_URL}/video_engagements`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.uid,
          videoId: id,
          liked: true,
          disliked: false,
        }),
      });

      if (response.ok) {
        console.log("Video liked successfully");
        const data = await response.json();
        setLikes(data.likes); // Update likes state
      } else {
        console.error("Error liking video");
      }
    } catch (err) {
      console.error("Error liking video", err);
    }
  };

  const handleVideoDislike = async () => {
    try {
      const response = await fetch(`${API_URL}/video_engagements`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.uid,
          videoId: id,
          liked: false,
          disliked: true,
        }),
      });

      if (response.ok) {
        console.log("Video disliked successfully");
        const data = await response.json();
        setDislikes(data.dislikes); // Update dislikes state
      } else {
        console.error("Error disliking video");
      }
    } catch (err) {
      console.error("Error disliking video", err);
    }
  };

  const handleVideoView = async () => {
    try {
      const response = await fetch(`${API_URL}/video_views`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.uid,
          videoId: id,
        }),
      });

      if (response.ok) {
        console.log("Video viewed successfully");
        // Fetch the updated video data and update the views state
        const videoResponse = await fetch(`${API_URL}/videos/${id}`);
        if (videoResponse.ok) {
          const videoData = await videoResponse.json();
          setViews(videoData.views);
        } else {
          console.error("Error fetching updated video data");
        }
      } else {
        console.error("Error logging view video event");
      }
    } catch (err) {
      console.error("Error logging view video event", err);
    }
  };

  const handleCreatorSubscribe = async () => {
    if (!user) {
      console.error("User is not authenticated");
      return;
    }
  
  try {
    const response = await fetch(`${API_URL}/users/creator_subscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user.uid,
        creator_user_id: videoData.user_id,
        is_subscribed: true
      })
    });
  
      if (response.ok) {
        console.log("Subscribed to creator successfully");
        const data = await response.json();
        console.log(data);
  
        // Fetch the updated number of subscribers
        const updatedResponse = await fetch(`${API_URL}/creator_subscriptions/${videoData.user_id}`);
        const updatedData = await updatedResponse.json();
        setSubscribers(updatedData.subscriberCount); // Update subscribers state
      } else {
        console.error("Error subscribing to creator");
      }
    } catch (err) {
      console.error("Error subscribing to creator", err);
    }
  };

  return (
    <div>
      <Searchbar />
      <div className="video-details-container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="video-content">
              <div className="video-stream">
                <video
                  key={id}
                  className="full-width-video"
                  src={`${API_URL}/videos/stream/${id}`}
                  title={videoData?.title}
                  controls
                  onPlay={handleVideoView}
                  ref={videoRef}
                ></video>
              </div>
              <h2>{videoData.title}</h2>

              {isTradeModalOpen && (
                <Dialog open={isTradeModalOpen} onClose={handleTradeModalClose}>
                  <DialogTitle>Trade</DialogTitle>
                  <DialogContent>
                    <TradeModal
                      id={videoData?.id}
                      tradeType={tradeType}
                      onClose={handleTradeModalClose}
                    ></TradeModal>
                  </DialogContent>
                </Dialog>
              )}

              <div>
                <div className="video-details-summary">
                  <Avatar
                    src={userData && userData.photo_url ? userData.photo_url : ""}
                    alt="Profile"
                    className="profile-icon"
                  />
                  <p>{userData?.display_name}</p>
                  <p>{subscribers} Subscribers</p>
                  <p>{views} views</p>
                  <p>
                    posted {" "}
                    {getDaysAgo(
                      new Date(videoData?.created_at).toLocaleDateString()
                    )}
                  </p>
                </div>
                
                <div className="video-engagement-buttons">
                  <TradeButton id={videoData.id} orderType={"buy"}></TradeButton>
                  <TradeButton id={videoData.id} orderType={"sell"}></TradeButton>
                  <button onClick={handleVideoLike}>Like {likes}</button>
                  <button onClick={handleVideoDislike}>Dislike {dislikes}</button>
                  <button onClick={handleCreatorSubscribe}>Subscribe</button>
                  </div>
                <div>
                  <p>{videoData.description}</p>
                </div>
              </div>

              <div className="video-stats">
                <table>
                  <thead>
                    <tr>
                      <th>Shares Outstanding</th>
                      <th>Price per Share</th>
                      <th>Market Cap.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {formatNumberWithCommas(
                          videoData?.shares_created - videoData?.shares_purchased
                        )}
                      </td>
                      <td>
                        ${videoData?.share_price}
                      </td>
                      <td>
                        $
                        {formatNumber(
                          videoData?.shares_created * videoData?.share_price
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="comments-and-related-videos">
                <div className="video-comments">
                  <CommentForm videoId={videoData.id} onCommentAdd={addComment} />

                  <div className="comment-array">
                    {comments
                      .sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((comment) => (
                        <Comment key={comment.id} {...comment} />
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="related-videos">
              <Video id={2} />
              <Video id={3} />
              <Video id={4} />
              <Video id={5} />
              <Video id={6} />
              <Video id={7} />
              <Video id={8} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default VideoDetails;
