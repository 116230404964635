import React, { useRef, useState } from "react";
import './VideoUpload.css';
import { useUser } from './UserContext';
import { toast, ToastContainer } from 'react-toastify';

const notifyUploadSuccess = () => toast.success("Resource successfully uploaded!");
const notifyUploadFailure = () => toast.error("Resource upload failed. Please ensure all fields are correct.");

const notifyUserSignInError = () => toast.error("Please create an account or sign in to upload a video.");

function VideoUpload({ user }) {
  const currentUser = useUser();
  const videoFileInputRef = useRef(); // Ref for video file input
  const thumbnailFileInputRef = useRef(); // Ref for thumbnail file input
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sharesCreated, setSharesCreated] = useState(0);
  const [sharePrice, setSharePrice] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      console.error('No user information available');
      notifyUserSignInError();
      return;
  }
    const formData = new FormData();
    formData.append("video", videoFileInputRef.current.files[0]);
    formData.append("thumbnail_image", thumbnailFileInputRef.current.files[0]);
    formData.append("user_id", user.uid);
    // formData.append("thumbnail_filepath", user.uid);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("shares_created", sharesCreated);
    formData.append("share_price", sharePrice);

    try {
      const response = await fetch(`${API_URL}/upload`, {
        method: "POST",
        body: formData,
      });

      console.log("Status:", response.status);
      console.log("Response text:", await response.text());

      if (response.ok) {
        console.log("Video uploaded successfully");
        notifyUploadSuccess();
      } else {
        console.log("Error uploading video");
        notifyUploadFailure();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <label>
        Video file:
        <input type="file" ref={videoFileInputRef} accept="video/*"/>
      </label>
      <label>
        Video Thumbnail Image:
        <input type="file" ref={thumbnailFileInputRef} accept="image/*"/>
      </label>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
      </label>
      <label>
        Description:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        ></textarea>
      </label>
      <label>
        Number of shares:
        <input
          type="number"
          value={sharesCreated}
          onChange={(e) => setSharesCreated(e.target.value)}
          placeholder="Number of shares"
        />
      </label>
      <label>
        Initial cost per share:
        <input
          type="number"
          value={sharePrice}
          onChange={(e) => setSharePrice(e.target.value)}
          placeholder="Initial cost per share"
        />
      </label>
      <button type="submit">Upload Video</button>
    </form>
  );
}

export default VideoUpload;
