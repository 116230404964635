import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signOut, // <-- Import signOut
} from "firebase/auth";

import { API_URL } from "../globals";

export const signInUser = async (user) => {
  const apiUrl = `${API_URL}/user`; // Change the URL to include the correct port

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    }),
  });
  return response.json();
};

export const signOutUser = () => {
  const authInstance = getAuth();
  signOut(authInstance).catch((error) => {
    console.error("Error signing out:", error);
  });
};

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  const authInstance = getAuth();
  signInWithPopup(authInstance, provider)
    .then((result) => {
      console.log("User object after Google sign-in:", result.user);
      return result.user;
    })
    .catch((error) => {
      console.error("Error signing in with Google:", error);
    });
};

export const signInWithFacebook = () => {
  console.log("Signing in with Facebook...");
  const provider = new FacebookAuthProvider();
  provider.addScope('email'); // Request the email permission
  const authInstance = getAuth();
  signInWithPopup(authInstance, provider)
    .then((result) => {
      console.log("User object after Facebook sign-in:", result.user);
      return result.user;
    })
    .catch((error) => {
      console.error("Error signing in with Facebook:", error);
    });
};
