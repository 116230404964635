import React, { useState, useEffect, useCallback } from "react";
import { useUser } from "./UserContext";
import "./TradeModal.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify"; // Import ToastContainer here

import { API_URL, formatNumberWithCommas } from "../globals";
const notifySuccess = () => toast.success("Trade successfully completed!");
const notifySignIn = () => toast.error("Please sign in to execute the trade.");

const TradeModal = ({ id, tradeType, onClose }) => {
  const currentUser = useUser();
  const [videoData, setVideoData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [shares, setShares] = useState(0); // User specified number of shares
  const [sharesOwned, setSharesOwned] = useState(0); // State for tracking the number of shares owned
  const [orderType, setOrderType] = useState("buy"); //buy or sell order
  const [orderMode, setOrderMode] = useState("market"); //market or limit order
  const [totalPrice, setTotalPrice] = useState(0); // Calculated total price

  const fetchVideoData = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/videos/${id}`);
      const data = await response.json();
      const userResponse = await fetch(`${API_URL}/user/${data.user_id}`);
      const userData = await userResponse.json();

      setVideoData(data);
      setUserData(userData);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  }, [id]);

  const fetchResourceOwnership = useCallback(async () => {
    if (!currentUser) {
      console.error("No user information available");
      return;
    }

    if (!videoData) {
      console.error("No video data available at the moment");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/getResourceOwnership/${currentUser.uid}/${videoData.id}`
      );
      if (response.ok) {
        const data = await response.json();

        setSharesOwned(data || 0);
      } else {
        console.error("Error fetching resource ownership data");
      }
    } catch (error) {
      console.error("Error fetching resource ownership data:", error);
    }
  }, [currentUser, videoData]);

  useEffect(() => {
    if (tradeType === "buy" || tradeType === "sell") {
      setOrderType(tradeType);
    }
  }, [tradeType]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchVideoData();
    };

    fetchData();
  }, [fetchVideoData]);

  useEffect(() => {
    const fetchData = async () => {
      if (videoData?.id) {
        await fetchResourceOwnership();
      }
    };

    fetchData();
  }, [fetchResourceOwnership, videoData]);

  // Function to calculate total price
  const calculateTotalPrice = () => {
    const price = videoData?.share_price || 0;
    setTotalPrice(shares * price);
  };

  const executeTrade = async () => {
    if (!currentUser) {
      console.error("No user information available");
      notifySignIn();
      return;
    }
  
    try {
      const creatorResponse = await fetch(`${API_URL}/user/${videoData.user_id}`);
      if (!creatorResponse.ok) {
        throw new Error('Failed to fetch creator details');
      }
      const creatorData = await creatorResponse.json();
      
      const tradeData = {
        userId: currentUser.uid,
        resourceId: videoData?.id,
        creatorId: videoData.user_id,
        creatorStripeAccountId: creatorData.stripe_account_id,
        shares: shares,
        orderType: orderType,
        sharePrice: videoData?.share_price,
        orderMode: orderMode,
        orderStatus: "pending",
      };
  
      const response = await fetch(`${API_URL}/trade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tradeData),
      });
  
      if (response.ok) {
        console.log("Trade executed successfully");
        notifySuccess(); // Trigger the success notification
        // Handle successful response
      } else if (response.status === 402) {
        const errorData = await response.json();
        console.error("Payment failed:", errorData.error);
        toast.error(errorData.error); // Display the error message from the server
        // Handle payment failure
      } else {
        console.error("Error executing trade");
        toast.error("An error occurred while executing the trade."); // Display a generic error message
        // Handle other error responses
      }
    } catch (error) {
      console.error("Error making the API call", error);
      toast.error("An error occurred while making the API call."); // Display a generic error message
      // Handle network error
    }
  
    const totalPrice = shares * videoData?.share_price;
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [shares]); // Recalculate total price whenever the number of shares changes

  return (
    <div>
      <div className="trade-modal">
        <div className="modal-header">
          <img
            src={videoData?.thumbnail_filepath}
            alt="Thumbnail"
            className="thumbnail"
          />
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
        <h2>{tradeType === "buy" ? "Buy now:" : "Sell now:"}</h2>
        <h2>{videoData?.title}</h2>

        <table>
          <div className="trade-modal-section order-details">
            <div>
              {/* <h1>Current user is {currentUser.uid}</h1> */}
              <h3>Order details:</h3>
              <tr>
                Trade to be Executed On: {new Date().toLocaleDateString()}
              </tr>
              <tr>${videoData?.share_price} price per share</tr>
              <tr>
                {formatNumberWithCommas(
                  videoData?.shares_created - videoData?.shares_purchased
                )}{" "}
                Shares Outstanding
              </tr>
              <tr>Shares you Own: {sharesOwned}</tr>
              {/* <tr>Asset Created by {userData.display_name}</tr> */}
            </div>
          </div>
          <p> Shares to {tradeType === "buy" ? "Buy:" : "Sell:"}</p>
          <input
            type="number"
            value={shares}
            onChange={(e) => setShares(e.target.value)}
            placeholder="Number of shares"
          />
          <select
            value={orderMode}
            onChange={(e) => setOrderMode(e.target.value)}
          >
            <option value="market">Market Order*</option>
            {/* <option value="limit">Limit</option> */}
          </select>
          <h3>Estimated Total: ${totalPrice.toFixed(2)}</h3>
          <h4>
            {orderMode === "market"
              ? "*Executes immediately at the current share price"
              : "*Executes at the price (buy or sell) set by trader"}
          </h4>
          <div className="investment-cta">
            <button onClick={executeTrade}>Execute Trade</button>
          </div>
        </table>
        <p>Important disclosure information</p>
        <p>
          Executes at the next available price when the market is open. By
          placing your order, you agree to Divvy's privacy notice and conditions
          of use.
        </p>
      </div>
    </div>
  );
};

export default TradeModal;
