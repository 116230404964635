import React, { useEffect, useState } from 'react';
import './WalletList.css';
import Video from './Video';
import { useUser } from './UserContext';
import TradeButton from './TradeButton';
import Searchbar from './Search/Header';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethod from './AddPaymentMethod';
import Dialog from "@mui/material/Dialog";
import { useNavigate, useSearchParams } from 'react-router-dom';
import './shared/GridLayout.css';
const API_URL = process.env.REACT_APP_API_URL;

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);

const Walletlist = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(searchParams.get('page')) || 1;
    });
    const currentUser = useUser();
    const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const [tradeModalAnchor, setTradeModalAnchor] = useState(null); // State to manage popover anchor
    const [tradeType, setTradeType] = useState(null); // Track trade type: 'buy' or 'sell'
    const navigate = useNavigate();

    // Calculate the total number of owned resources
    const totalOwnedResources = resources.length;
    const totalPortfolioValue = resources.reduce((total, resource) => {
        return total + (resource.shares_owned * resource.share_price);
      }, 0);
      
    // Function to open the TradeModal
    const openTradeModal = (tradeType) => {
        setTradeType(tradeType);  // Set the trade type based on the button clicked
        setIsTradeModalOpen(true);
    };
    const handleTradeModalClose = () => {
        setTradeModalAnchor(null);
        setIsTradeModalOpen(false);
    };

    const fetchResources = async (page) => {
        if (!currentUser?.uid) return;
        
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/listAllOwnedResources?user_id=${currentUser.uid}&limit=5&offset=${(page - 1) * 10}`);
            if (!response.ok) {
                throw new Error(`Fetch error: ${response.statusText}`);
            }
            const data = await response.json();
            const resourcesWithDetails = await Promise.all(
                data.map(async (obj) => {
                  const resource_id = obj['resource_id'];
                  const resource_info = await fetchVideoData(resource_id);
                  return { ...obj, ...resource_info };
                })
            );
            setResources(resourcesWithDetails);
        } catch (error) {
            console.error('Error fetching resources:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchVideoData = (async (resource_id) => {
        try {
          const response = await fetch(`${API_URL}/videos/${resource_id}`);
          const data = await response.json();
          return data;
        //   console.log(data)
        } catch (error) {
          console.error("Error fetching video data:", error);
        }
      });

    useEffect(() => {
        if (currentUser?.uid) {
            fetchResources(currentPage);
        }
    }, [currentPage, currentUser?.uid]);

    const handlePaymentModalClose = () => {
        setIsPaymentModalOpen(false);
    };

    const handleSearch = (event) => {
        // Implement search functionality if needed
        console.log('Search term:', event.target.value);
    };

    // Update URL when page changes
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setSearchParams({ page: newPage });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!currentUser) {
        return <div>Please log in to view your wallet</div>;
    }

    return (
        <div>
            <Searchbar onSearchChange={handleSearch} />
            <div className="wallet-container">
                <div className="wallet-header">
                    <div className="portfolio-summary">
                        <div className="metric">
                            <h2 className="metric-value">{totalOwnedResources}</h2>
                            <p className="metric-label">Total Owned Resources</p>
                        </div>
                        <div className="metric">
                            <h2 className="metric-value">${totalPortfolioValue.toFixed(2)}</h2>
                            <p className="metric-label">Total Portfolio Value</p>
                        </div>
                    </div>
                    <div className="header-actions">
                        <button 
                            className="action-button"
                            onClick={() => setIsPaymentModalOpen(true)}
                        >
                            Add Payment Method
                        </button>
                        <button 
                            className="action-button"
                            onClick={() => navigate('/order-history')}
                        >
                            Order History
                        </button>
                    </div>
                </div>

                {resources.length === 0 ? (
                    <div className="no-resources-message">
                        No resources found in your wallet
                    </div>
                ) : (
                    <>
                        <div className="resources-grid">
                            {resources.map(resource => (
                                <div key={resource.resource_id} className="resource-card">
                                    <Video id={resource.resource_id} />
                                    <div className="resource-details">
                                        <div className="resource-stats">
                                            <div className="stat-group">
                                                <span>Market Cap: ${(resource.shares_created * resource.share_price).toFixed(2)}</span>
                                                <span>Price/Share: ${resource.share_price}</span>
                                            </div>
                                            <div className="stat-group">
                                                <span>Shares Owned: {resource.shares_owned}</span>
                                                <span>Total Value: ${(resource.shares_owned * resource.share_price).toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="trade-actions">
                                            <TradeButton id={resource.resource_id} orderType={'buy'} />
                                            <TradeButton id={resource.resource_id} orderType={'sell'} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className="page-indicator">Page {currentPage}</span>
                            <button 
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={resources.length < 5}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}

                <Dialog open={isPaymentModalOpen} onClose={handlePaymentModalClose}>
                    <Elements stripe={stripePromise}>
                        <AddPaymentMethod onClose={handlePaymentModalClose} />
                    </Elements>
                </Dialog>
            </div>
        </div>
    );
};

export default Walletlist;